import Component from '../core/Component'

export default class Unfold extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        this.element.addEventListener('click', event => {
            event.stopPropagation();
            document.querySelector(this.element.hash).classList.toggle('is-unfolded');
        });
    }
}