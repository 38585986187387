import Component from '../core/Component'
import { disableScrolling, enableScrolling } from '../services/Viewport'

export const STATES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
    LOADING: 'is-loading',
    FULLSCREEN: 'is-fullscreen'
}

export default class Modal extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            closeButton: null,
        }

        this.isActive = false
    }

    prepare() {
        this.ref.closeButton.addEventListener("click", this.close.bind(this))

        this.opener = document.querySelector('[data-rel="ModalOpener"]')

        if (this.opener) {
            this.opener.addEventListener("click", this.open.bind(this))
        }
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown.bind(this))
        this.ref.closeButton.removeEventListener("click", this.close.bind(this))
        this.opener.removeEventListener("click", this.open.bind(this))

        enableScrolling()
    }

    handleKeyDown(event) {
        if (event.key === 'Escape') {
            this.close()
        }
    }

    open() {
        this.isActive = true
        disableScrolling()
        this.element.classList.add(STATES.ACTIVE)
        document.addEventListener('keydown', this.handleKeyDown.bind(this))

    }

    close(event) {
        if (!this.isActive) {
            return
        }
        event.preventDefault()

        this.isActive = false
        enableScrolling()
        this.element.classList.remove(STATES.ACTIVE)
        document.removeEventListener('keydown', this.handleKeyDown.bind(this))
    }
}
