import Component from '../../js/core/Component';

export default class Hero extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            headItems: [],
            items: [],
        };
    }

    prepare() {
        if(this.ref.items.length <= 1) {
            return;
        }

        this.ref.headItems.forEach(item => {
            item.addEventListener('click', event => {
                let i = 0;
                this.ref.headItems.forEach((item, index) => {
                    if(item === event.target) {
                        i = index;
                    }
                });
                this.activate(i);
            });
        });

        this.activate(0);
    }

    activate(index) {
        this.setState({
            ...this.state,
            index: index,
        });
    }

    stateChange(stateChanges) {
        if('index' in stateChanges) {
            this.ref.items.forEach(item => {
                item.classList.remove('is-active');
            });
            this.ref.headItems.forEach(item => {
                item.classList.remove('is-active');
            });
            this.ref.headItems[stateChanges.index].classList.add('is-active');
            this.ref.items[stateChanges.index].classList.add('is-active');
        }
    }
}