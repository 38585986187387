import Component from '../core/Component';
import GoogleMapsLoader from 'google-maps';
import debounce from 'lodash/debounce';
import { createMarker } from './Marker'
GoogleMapsLoader.KEY = 'AIzaSyCLUTHjtm-iFICyb_4a0W_WpnOTEflk1oI';

export default class GoogleMap extends Component {

    constructor(element) {
        super(element);

        this.latlng = null;
        this.isReady = false;

        this.$win = $(window);
    }

    prepare() {
        this.coords =  {
            lat: this.element.dataset.lat,
            lng: this.element.dataset.lng
        }

        GoogleMapsLoader.load(::this.render);

        this.$win.on('resize.googleMap orientationchange.googleMap', debounce(::this.center, 200));
    }

    render(google) {
        const latlng = new google.maps.LatLng(this.coords.lat, this.coords.lng);
        this.google = google;
        this.latlng = latlng;

        this.map = new google.maps.Map(this.element, {
            center: latlng,
            zoom: 12,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: false,
            scaleControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: [
                        {
                            "featureType": "administrative",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": "-100"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.province",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 65
                                },
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": "50"
                                },
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": "-100"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "lightness": "30"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "lightness": "40"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "hue": "#ffff00"
                                },
                                {
                                    "lightness": -25
                                },
                                {
                                    "saturation": -97
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "lightness": -25
                                },
                                {
                                    "saturation": -100
                                }
                            ]
                        }
                    ]
        });
        const Marker = createMarker(google);

        var marker = new Marker({
            position: latlng,
            map: this.map
        });

        setTimeout(() => {
            this.isReady = true;
            this.center();
        }, 0);
    }

    center() {
        if (this.isReady) {
            this.google.maps.event.trigger(this.map,'resize');
            this.map.setCenter(this.latlng);
        }
    }

    destroy() {
        this.$element.detach();
        this.map = null;
        this.$win.off('.googleMap');
    }
}