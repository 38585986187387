import Component from '../core/Component'

window.$.datepicker.regional['cs'] = {
    closeText: 'Zavřít',
    prevText: '&#x3c;Dříve',
    nextText: 'Později&#x3e;',
    currentText: 'Nyní',
    monthNames: ['leden','únor','březen','duben','květen','červen',
    'červenec','srpen','září','říjen','listopad','prosinec'],
    monthNamesShort: ['led','úno','bře','dub','kvě','čer',
    'čvc','srp','zář','říj','lis','pro'],
    dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
    dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
    dayNamesMin: ['ne','po','út','st','čt','pá','so'],
    weekHeader: 'Týd',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''};

export default class Datepicker extends Component {
    constructor(element) {
        super(element);

        window.$.datepicker.setDefaults(window.$.datepicker.regional[element.dataset.lang]);
    }

    prepare() {
        if (window.innerWidth >= 767) {
            let value = this.element.value;
            this.element.value = "";
            window.$(this.element).datepicker({
                dateFormat : 'dd MM',
                defaultDate: new Date(value),
                changeYear: true,
                changeMonth: true,
            });
            window.$(this.element).datepicker('setDate', new Date(value));
        } else {
            this.element.type = 'date';
        }
    }
}