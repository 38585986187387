import Component from '../core/Component';

export default class Hero extends Component {
    constructor(element) {
        super(element);

        let options = {};
        if (this.element.dataset.options != null) {
            options = JSON.parse(this.element.dataset.options);
        }

        let defaults = {

        }

        this.options = {
            ...defaults,
            ...options
        }
    }

    async require() {

    }

    prepare() {
        if(this.options.video && window.innerWidth >= 1200) {
            let video = `<video preload='auto' loop autoplay muted>
                <source src='${this.options.video.mp4}' type='video/mp4'>
                </video>`;

            this.ref.video.innerHTML = video;
        }


        if(!this.ref.items) {
            return;
        }
        if(this.ref.arrowRight && this.ref.arrowLeft) {
            this.ref.arrowRight.addEventListener('click', ::this.next);
            this.ref.arrowLeft.addEventListener('click', ::this.previous);
        }

        this.setState({
            index: 0,
        });
    }

    next() {
        let index = this.state.index < this.ref.items.length-1 ? this.state.index+1 : 0;
        this.setState({
            ...this.state,
            index: index,
        });
    }

    previous() {
        let index = this.state.index > 0 ? this.state.index-1 : this.ref.items.length - 1;
        this.setState({
            ...this.state,
            index: index,
        });
    }

    display(index = this.state.index) {
        this.ref.items.forEach(item => {
            item.classList.remove('is-active');
        })
        this.ref.items[index].classList.add('is-active');
        clearTimeout(this.timeout);
        this.timeout = setTimeout(::this.next, 4000);
    }

    stateChange(stateChanges) {
        if('index' in stateChanges) {
            this.display(stateChanges.index);
        }
    }
}