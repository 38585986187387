import throttle from 'lodash/throttle'

import Component from '../core/Component'

export default class Bar extends Component {
    constructor(element) {
        super(element);
        this.scrollHandler = throttle(::this.scroll);
        this.resizeHandler = throttle(::this.resize);
        this.resize();
    }

    prepare() {
        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.resizeHandler);
        this.ref.submit.addEventListener('click', event => {
            event.preventDefault();
            let from = new Date(window.$(this.ref.from).datepicker('getDate').toUTCString());
            let to = new Date(window.$(this.ref.to).datepicker('getDate').toUTCString());
            let settings = {
                datein: (from.getFullYear())+"-"+(from.getMonth() + 1)+"-"+from.getDate(),
                dateout: (to.getFullYear())+"-"+(to.getMonth() + 1)+"-"+to.getDate(),
                currency: 'CZK'
            }

            let baseUrl = '';

            if (document.documentElement.lang !== 'cs') {
                baseUrl += "/" + document.documentElement.lang;
            }

            baseUrl += '/bookolo'

            let url = new URL(baseUrl, window.location.origin)

            Object.keys(settings).forEach(key => {
                url.searchParams.append(key, settings[key])
            });

            window.location.href = url.toString()

        });

        this.setState({
            show: true
        });
    }

    destroy() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.resizeHandler);
    }

    scroll(event) {
        if(window.pageYOffset > this.limit) {
            this.setState({
                ...this.state,
                show: false,
            });
        } else {
            this.setState({
                ...this.state,
                show: true,
            });
        }
    }

    resize() {
        let body = document.body,
            html = document.documentElement;
        let height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        this.limit = height - window.innerHeight - 500;
    }

    stateChange(stateChanges) {
        if('show' in stateChanges) {
            let show = stateChanges.show ? "add" : "remove";
            document.body.classList[show]('bar-is-active');
        }
    }
}
