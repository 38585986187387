// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import 'console-polyfill';

import components from './components/';
import { loadComponents } from './core/componentUtils';

window.app = {
    components: components
};

loadComponents();

// formy (cizí kód)
$( document ).on( 'click', '#faSame input', function() {
    if( $( this ).prop( 'checked' ) == true ) {
        $( '.faSameDiv' ).hide() ;
    } else {
        $( '.faSameDiv' ).show() ;
    }
} ) ;
