import Component from '../core/Component'

export default class Bar extends Component {
    constructor(element) {
        super(element)

        let options = {}
        if (this.element.dataset.options != null) {
            let rawOptions = this.element.dataset.options

            let parts = rawOptions.split(":")
            if (parts.length === 2) {
                let key = parts[0].trim()
                let value = parseInt(parts[1].trim())

                options[key] = value
            }
        }

        let defaults = {
            // options, defaults listed
            language: document.documentElement.lang || 'en',
            currency: 'CZK',

        }

        this.options = {
            ...options,
            ...defaults
        }
    }

    prepare() {
        if (this.element.tagName.toLocaleLowerCase() !== "a") {
            this.element.addEventListener('click', event => {
                event.preventDefault()
                let settings = {
                    currency: 'CZK'
                }

                let baseUrl = '';

                if (document.documentElement.lang !== 'cs') {
                    baseUrl += "/" + document.documentElement.lang;
                }

                baseUrl += '/bookolo'

                let url = new URL(baseUrl, window.location.origin)

                Object.keys(settings).forEach(key => {
                    url.searchParams.append(key, settings[key])
                });

                if (this.options.OfferID) {
                    url.searchParams.append('preferpackage', this.options.OfferID);
                }

                if (this.options.RoomID) {
                    url.searchParams.append('preferroom', this.options.RoomID);
                }

                window.location.href = url.toString()
            });
        }
    }
}
