import Component from '../core/Component'
import Flickity from 'flickity'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import { toggleClass } from '../utils/dom'

export const STATUS = {
    READY: 'is-ready',
}

export default class ReviewSlider extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            content: null,
            slides: [],
            dots: [],
            buttonNext: null,
            buttonPrev: null,
        }

        this.current = 0
        this.timer = null

        this.dragging = false

        this.handleResize = throttle(this.handleResize, 100)
        this.handleWindowBlur = debounce(this.handleWindowBlur, 50)
        this.handleWindowFocus = debounce(this.handleWindowFocus, 50)
    }

    prepare() {
        this.resize()

        if (this.ref.slides.length > 1) {
            this.element.classList.add(STATUS.READY)

            this.flickity = new Flickity(this.ref.content, {
                cellSelector: '[data-ref="slide"]',
                prevNextButtons: false,
                wrapAround: true,
                pageDots: false,
                resize: true,
            })

            this.ref.buttonNext.addEventListener('click', (event) => {
                event.preventDefault()
                this.stop()
                this.flickity.next()
                this.start()
            })

            this.ref.buttonPrev.addEventListener('click', (event) => {
                event.preventDefault()
                this.stop()
                this.flickity.previous()
                this.start()
            })

            this.flickity.on('select', this.handleSelect)
            this.flickity.on('dragStart', this.handleDragStart)
            this.flickity.on('dragEnd', this.start)
            this.flickity.on('settle', this.handleSettle)

            this.element.addEventListener('mouseenter', this.stop)
            this.element.addEventListener('mouseleave', this.start)
            this.element.addEventListener('click', this.handleClick)

            window.addEventListener('resize', this.handleResize)
            window.addEventListener('blur', this.handleWindowBlur)
            window.addEventListener('focus', this.handleWindowFocus)

            this.handleSelect(0)

            this.start()
        }
    }

    destroy() {
        this.stop()
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('blur', this.handleWindowBlur)
        window.removeEventListener('focus', this.handleWindowFocus)
    }

    handleWindowFocus = (event) => {
        this.start()
    }

    handleWindowBlur = (event) => {
        this.stop()
    }

    handleResize = () => {
        this.resize()
    }

    handleClick = (event) => {
        if (this.dragging) {
            event.stopPropagation()
            event.preventDefault()
        }
    }

    handleSelect = (current) => {
        if (this.ref.dots) {
            this.ref.dots.forEach((dot, index) => toggleClass(dot, 'is-active', index === current))
        }

        if (!current > 0) {
            this.ref.buttonPrev.classList.add('is-disabled')
        } else {
            this.ref.buttonPrev.classList.remove('is-disabled')
        }

        if (current === this.ref.slides.length - 1) {
            this.ref.buttonNext.classList.add('is-disabled')
        } else {
            this.ref.buttonNext.classList.remove('is-disabled')
        }
    }

    handleDragStart = () => {
        this.dragging = true
        this.stop()
    }

    handleSettle = () => {
        this.dragging = false
    }

    resize = () => {
        if (this.flickity) {
            this.flickity.resize()
        }
    }

    stop = () => {
        clearInterval(this.timer)
    }

    start = () => {
        this.stop()
        this.timer = setInterval(this.next, 6000)
    }

    next = () => {
        this.flickity.next()
    }
}
