import Component from '../core/Component'

export default class Bookolo extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        const language = document.documentElement.lang
        this.element.dataset.language = language
    }
}
