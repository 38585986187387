import Component from '../core/Component'

export default class Video extends Component {
    constructor(element) {
        super(element)
        this.video = this.element.querySelector('video')
    }

    prepare() {
        this.element.addEventListener('click', (event) => {
            this.video.controls = "controls";
            if (this.state.status == "played") {
                this.setState({
                    ...this.state,
                    status: "paused",
                });
            } else {
                this.setState({
                    ...this.state,
                    status: "played",
                });
            }
        })
    }

    stateChange(stateChange) {
        if('status' in stateChange) {
            if (stateChange.status == "played") {
               this.video.play();
               this.element.classList.add('is-playing');
               this.element.classList.remove('is-paused');
           } else if (stateChange.status == "paused") {
               this.video.pause();
               this.element.classList.add('is-paused');
               this.element.classList.remove('is-playing');
           }
        }
    }
}