import Component from '../../js/core/Component';
import Cookies from 'js-cookie';

export const STATUS = {
    ACTIVE: 'is-active'
}

export default class CookieBubble extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            cookieButton: null
        }
    }

    prepare() {
        if (!Cookies.get('cookiesAgreement')) {
            Cookies.set('cookietest', 1);
            if (Cookies.get('cookietest')) {
                Cookies.remove('cookietest');
                this.ref.cookieButton.addEventListener('click', ::this.handleClick);
                this.show();
            }
        }
    }

    handleClick(event) {
        event.preventDefault();
        this.hide();
    }

    show() {
        this.element.classList.add(STATUS.ACTIVE);
    }

    hide() {
        this.element.classList.remove(STATUS.ACTIVE);
        Cookies.set('cookiesAgreement', 1, {
            expires: 365
        });
    }
}