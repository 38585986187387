import Component from '../core/Component';

export default class MenuOpener extends Component {

    constructor(element) {
        super(element);
    }

    prepare() {
        this.element.addEventListener('click', event => {
            if (document.documentElement.classList.contains('menu-is-opened')) {
                this.setState({
                    open: false,
                });
            } else {
                this.setState({
                    open: true,
                });
            }
        })

        document.addEventListener('keydown' ,function(event) {
            if (event.keyCode == 27 && document.documentElement.classList.contains('menu-is-opened')) {
                this.setState({
                    open: false,
                });
            }
        });

        this.setState({
            open: false,
        });
    }

     stateChange(stateChanges) {
        if('open' in stateChanges) {
            let open = stateChanges.open ? "add" : "remove";
            document.documentElement.classList[open]('menu-is-opened');
        }
    }
}