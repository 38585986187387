import Component from '../core/Component'
import throttle from 'lodash/throttle'

export default class Explode extends Component {
    constructor($element) {
        super($element)

        // this.ref = {
        //     explode: []
        // }

        // options
        let options = {};
        if (this.element.dataset.options != null) {
            options = JSON.parse(this.element.dataset.options);
        }
        let defaults = {
            offset: 200,
        }

        this.options = {
            ...defaults,
            ...options
        }

        this.sections = this.ref.sections;
    }

    prepare() {
        this.scrollHandler = throttle(::this.scroll);
        this.resizeHandler = throttle(::this.resize);
        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.resizeHandler);

        this.setState({
            displaySections: [],
            borders: [],
        });

        this.resize();
        this.scroll();
    }

    destroy() {
        window.removeEventListener('scroll', this.scrollHandler)
        window.removeEventListener('resize', this.resizeHandler);
    }

    resize(event) {
        let body = document.body,
            html = document.documentElement;

        // window/document
        this.windowHeight = window.innerHeight;

        //items
        let borders = [];
        this.sections.forEach(item => {
            let rect = item.getBoundingClientRect();
            borders.push({
                top: rect.top + window.pageYOffset,
                bottom: rect.top + window.pageYOffset + item.offsetHeight
            });
        });

        this.setState({
            ...this.state,
            displaySections: [],
            borders: borders,
        });
    }

    scroll() {
        let displaySections = [];
        this.state.borders.forEach((item, index) => {
            // is in viewport
            if (item.top < window.pageYOffset + this.windowHeight - this.options.offset && item.bottom > window.pageYOffset + this.options.offset) {
                displaySections[index] = true;
            } else {
                displaySections[index] = false;
            }
        });

        this.setState({
            ...this.state,
            displaySections: displaySections,
        });
    }

    stateChange(stateChanges) {
        if('displaySections' in stateChanges) {
            stateChanges.displaySections.forEach((item, index) => {
                if(item) {
                    this.sections[index].classList.add('is-in-viewport');
                    this.sections[index].classList.add('has-displayed');
                } else {
                    this.sections[index].classList.remove('is-in-viewport');
                }
            });
        }
    }
}